import React, {useState, useRef, useEffect} from 'react'
import { Provider, useSelector } from "react-redux";
import {TextInline, Grid, Col, Row, Title, ResponsiveImage, SubTitle, CardTitle, A, BRL, OnlySmall, OnlyLarge, Section, Wrapper, ContainerPadding} from '../theme'
import {logoLarge, logoSmall, MoreIcon, ExpandIcon, AddIcon, RemoveIcon, ContaineredIcon, containerWidth} from '../theme/icons';
import ContextualMenu from './ContextualMenu'
import Collapsible from './Collapsible'
import Expand from 'react-expand-animated';
import styled from 'styled-components';
import toast  from '../services/toast';

const Menu = styled.div`
    float: right;
`

const Textarea = styled.textarea`
width: 100%;
padding: 5px;
box-sizing: border-box;
font-size: 15px;
resize: none;
`

export const CardList =  ({name, courses, passed, required})=>{
    const mycourses = courses.filter(course=>course.passed===passed);

    return (
        <Collapsible key={name} title={name} secondText={(required? `(${mycourses.length}/${required})`:null)} noPadding noWrapper>
            {mycourses.map((course, index)=>(
                <Card   key={name+index} 
                        id={course._id} 
                        name={course.name} 
                        note={course.note} 
                        trimesters={course.trimester} 
                        noBottom={index < courses.length-1} 
                        noTop={index==0} 
                        page={course.course_page} 
                        requirements={course.requirements}
                        creditRequirements = {course.credit_requirements}
                        initheight = {null}
                        passed = {course.passed}
                        credits = {course.credits}
                        />
            )
        )}
        </Collapsible>
    )
}

export const Card = ({noBottom, noTop, trimesters, page, note, id, name, requirements, creditRequirements, initheight, passed, credits})=>{

    const courseData = useSelector(state=>state.data.courseData);

    const [open, setOpen] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [transit, setTransit] = useState(false);
    const [height, setHeight] = useState(initheight);
    const [editNote, setEditNote] = useState(false);
    const [noteValue, setNoteValue] = useState(note?note.content:'')
    const ref = useRef();
    const duration = 400;
    const padder = "7px";
    const titleHeight = 60;
    const extendedMenuHeight = 200;

    useEffect(() => {
        if (ref && ref.current && ref.current.offsetHeight && height==null){
            setHeight(ref.current.offsetHeight)
            // setTimeout(() => {
            //     setHeight(ref.current.offsetHeight)
            // }, duration+50);
        }

        const timer = setInterval(() => {
            if (ref){
                setHeight(ref.current.offsetHeight)
            }
        }, 1000);

        return () => {
            clearInterval(timer);
        };
    }, [ref, height, setHeight, height, setHeight]);

    const toggle = ()=>{
        setOpen(!open);
        setTransit(true);
        setTimeout(() => {
            setTransit(false);
        }, duration);
        setHeight(null);
    }

    const saveNote = ()=>{
        courseData.editNote(noteValue, id);
        setEditNote(false);
        setMenuOpen(false);
    }

    const setPassed = (value)=>{
        toast(`${name} ${value?'added to':'removed from'} passed courses`);
        setMenuOpen(false);
        courseData.setPassed(value, id);
        setHeight(null);
    }

    const getMover = ()=>{
        if (passed){
            return <Wrapper noTop noRight onClick={()=>{setPassed(false)}}>
                        <ContainerPadding size={padder}>
                            <b>remove from passed</b>
                        </ContainerPadding>
                    </Wrapper>
        } else if (courseData && courseData.canAddToPassed(id)){
            return <Wrapper noTop noRight onClick={()=>{setPassed(true)}}>
                        <ContainerPadding size={padder}>
                        <b>add to passed</b>
                        </ContainerPadding>
                    </Wrapper>
        } else {
            return <Wrapper noTop noRight onClick={()=>{
                        setPassed(true);
                        toast(`You marked ${name} as passed without fulfilling one or more of its requirements`)}}>
                        <ContainerPadding size={padder}>
                            <b style={{color: "#8E1837"}}>add to passed</b>
                        </ContainerPadding>
                    </Wrapper>
        }
    }


    const getMenu = ()=>{
        if (!open){
            return (
                <Menu>
                    <Section onClick={toggle}>
                        <ContaineredIcon style={{float: "left"}} icon={ <ExpandIcon  />}  noBottom={!open && !transit && height && height < titleHeight} noTop={true} noRight={true}/>
                    </Section>
                </Menu>  )

        } else if (editNote){
            return (
                <Menu>
                    <Wrapper noTop noRight onClick={()=>{ saveNote() }}>
                        <ContainerPadding size={padder}>
                            <b>save</b>
                        </ContainerPadding>
                    </Wrapper>
                    <Wrapper noTop noRight onClick={()=>
                        {
                            setHeight(null);
                            setEditNote(false);
                        }}>
                        <ContainerPadding size={padder}>
                            <b>cancel</b>
                        </ContainerPadding>
                    </Wrapper>
                </Menu> 
            )
        } else if (!menuOpen) {
            return (
                <Menu>
                    <Section onClick={()=>{setMenuOpen(true)}}>
                        <ContaineredIcon style={{float: "left"}} icon={ <MoreIcon  />}  noBottom={!open && !transit && height && height < titleHeight} noTop={true} noRight={true}/>
                    </Section>
                </Menu>  )
        }  else {
            return (
                <Menu>
                    <Wrapper noTop noRight onClick={()=>{toggle(); setMenuOpen(false);}}>
                        <ContainerPadding size={padder}>
                            <b>close</b>
                        </ContainerPadding>
                    </Wrapper>
                    <Wrapper noTop noRight onClick={()=>
                        {
                            setHeight(null);
                            setEditNote(true);
                        }}>
                        <ContainerPadding size={padder}>
                            <b>edit notes</b>
                        </ContainerPadding>
                    </Wrapper>
                    {getMover()}
                    <Wrapper noTop noRight noBottom={height?height < extendedMenuHeight : true} onClick={()=>{setMenuOpen(false);}}>
                    <ContainerPadding size={padder}>
                        <b>cancel</b>
                    </ContainerPadding>
                    </Wrapper>
                </Menu>  )
        }
    }

    const getNote = ()=>{
        
        if (editNote){
            return <span>
                <BRL/><b>Edit note</b>
                <Textarea rows="4" cols="50" value={noteValue} onChange = {e => setNoteValue(e.target.value)}/>
            </span>
        } else {
            if (courseData){
                let note = courseData.getNote(id);
                if (note && note!==''){
                    return <span><BRL/><b>Note</b><br/>{note} </span>
                } else {
                    return null;
                }
            } else {
                return null;
            }
        }   
    }

    const getRequirements = ()=>{
        if ((requirements && requirements !== undefined && requirements.length > 0) || (creditRequirements &&  creditRequirements!==undefined && creditRequirements > 0)){
            return <span>
                <BRL/>
                <b>requirements</b>
                <br/>
                {requirements?requirements.map((req, index)=><span key={index}>{req.name} {index < (requirements.length-1)?', ':null} </span>):null}
                {creditRequirements?<span>Required credit units: {creditRequirements}</span>:null}
                </span>
        } else {
            return null;
        }
    }

    const getCredits = ()=>{
        if (credits){
            return <span>
                <BRL/>
                <b>academic units: </b> {credits}
                </span>
        } else {
            return null;
        }
    }

    return (
        <Wrapper noBottom={noBottom} noTop={noTop} ref={ref}>
            <Row >
                <Col xs={10}>
                    <ContainerPadding size={padder}>
                        <CardTitle >
                            {name}
                        </CardTitle>
                        <Expand open={open} duration={duration}>
                            <TextInline>
                            {trimesters.map((trimester, index)=><i key={index}>{trimester}{index<(trimesters.length-1)?", ":null}</i>)}
                            {page? <span>, <a href={page} style={{textDecoration: "underline"}} target="_blank">course page</a></span>:null}
                            {getNote()}
                            {getRequirements()}
                            {getCredits()}
                            </TextInline>
                        </Expand>
                    </ContainerPadding>
                </Col>
                <Col xs={2}>
                    {getMenu()}
                </Col>
            </Row>            
        </Wrapper>
    )
}
