

import React, {useState} from 'react'
import {Grid, Col, Row, Title, ResponsiveImage, SubTitle, Text, BRL, BRS, OnlySmall, OnlyLarge, Section, Wrapper, ContainerPadding, TextInline} from '../theme'
import {margins} from "../theme/config.json";
import {logoLarge, logoSmall} from '../theme/icons';




export default ()=>{
    return <Grid style={{paddingTop: margins.grid.top}}>

<Row center="xs">
            <Col xs={8}>
                <OnlySmall>
                    <a href="/">
                    <ResponsiveImage src={logoSmall} />
                    </a>
                </OnlySmall>
                <OnlyLarge>
                <a href="/">
                    <ResponsiveImage src={logoLarge} />
                    </a>
                </OnlyLarge>
            </Col>
        </Row>
        
        <Row>
            <Col xs={12}>
                <Title>
                    Cookie and Legal Policy
                </Title>
                <br/>
                Like any other website, SEADS uses 'cookies'. These cookies are used to store information including visitors' preferences, and the pages on the website that the visitor accessed or visited. The information is used to optimize the users' experience by customizing our web page content based on visitors' browser type and/or other information. By using SEADS website you consent to the use of cookies.
                <SubTitle>
                What are Cookies?
                </SubTitle>
                <br/>
                Most of the modern day interactive web sites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting. Some of our affiliate / advertising partners may also use cookies. A cookie consists of information when you visit a site which helps remember things; such as preferences, login information, history and tracks your site activity.Our website temporarily stores cookie in your computer when you visit it. It is a file located within your web browser.

                <SubTitle>
                Our cookie usage
                </SubTitle>
                <br/>
                We use Google Analytics to help us understand our visitors behavior and collect information in order to improve our site.
                
                <SubTitle>
                Planner tool
                </SubTitle>
                <br/>
                The planner app is a tool that helps students from UPOU, University of the Philippines Open University, to plan their courses. Logging in to the platform works through Google Authentification, and works only if you are using @upou.edu.ph or @up.edu.ph email accounts. The platform uses your authenticated UP/UPOU email account to log you in, and therefore only uses your name and your email account. No other personal information is used.
            </Col>
        </Row>
    </Grid>
}