import React, {useState} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Collapsible from './Collapsible'
import Button, {Select} from './Button'
import {Wrapper, ContainerPadding, TextInline, Section} from '../theme'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import LineInput from './LineInput'

export const STATES = Object.freeze({
    INIT: Symbol("data/client"),
    NEXT: Symbol('data/student'),
});


export default ()=>{
    // const data = useSelector(state => state.data);
    const [client, curricula] = useSelector(state=>[state.data.client, state.data.curricula]);
    const [curriculum, setCurriculum] = useState(null);
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [firstname, setFirstname] = useState('');
    const [lastname, setLastname] = useState('');
    const [year, setYear] = useState(2007);
    const [term, setTerm] = useState(0);
    const [legacy, setLegacy] = useState(false);
    const [studentNumber, setStudentNumber] = useState('')
    const width = "110px";

    // const getCurricula = ()=>Array.from(new Array(3), (v, i) => <option key={i} value={i}>term {i+1}</option>)
    const getCurricula = ()=>curricula.map(c=><option key={c._id} value={c._id}>{c.name}</option> )
    const getTerms = ()=>Array.from(new Array(3), (v, i) => <option key={i} value={i}>term {i+1}</option>)

    const getYears = () => {
        const year = 2007;
        return (
          Array.from( new Array(new Date().getFullYear() - year + 2), (v,i) =>
            <option key={i} value={year+i}>{year+i}</option>
          )
        );
      };    

    if (!client || !curricula){
        return null;
    } else {
        return (
            <Collapsible title="Select curriculum" initialOpen noPadding noWrapper>
                <LineInput value={firstname} setValue={setFirstname} title="first name" width={width} noBottom noTop/>
                <LineInput value={lastname} setValue={setLastname} title="last name" width={width} noBottom/>
                <LineInput value={email} setValue={setEmail} title="email" width={width} noBottom/>
                <LineInput value={studentNumber} setValue={setStudentNumber} title="student number" width={width} noBottom/>
                <Wrapper>
                    <ContainerPadding size={"10px"}>
                        <TextInline>
                            <b style={{width: "500px"}}>
                                Year first enrolled
                            </b>
                            <Select onChange={e => setYear(e.target.value)}>
                            {getYears()}
                        </Select>
                        </TextInline>
                    </ContainerPadding>
                </Wrapper>
                <Wrapper noTop noBottom>
                    <ContainerPadding size={"10px"}>
                        <TextInline>
                            <b  style={{width: "500px"}}>
                                Term first enrolled
                            </b>
                            <Select onChange={e => setTerm(e.target.value)}>
                            {getTerms()}
                        </Select>
                        </TextInline>
                    </ContainerPadding>
                </Wrapper>

                <Wrapper noBottom>
                    <ContainerPadding size={"10px"}>
                        <Select onChange={e => setCurriculum(e.target.value)}>
                            {getCurricula()}
                        </Select>
                    </ContainerPadding>
                </Wrapper>

                <Wrapper >
                    <ContainerPadding size={"10px"}>
                    <div style={{padding: "14px"}}>
                    <Button text="NEXT" onClick={()=>{
                        console.log(year)
                        dispatch({type: DATA_ACTIONS.SET_STUDENT, student: {
                            _id: null,
                            email: email || "no email",
                            notes: [],
                            planned_courses: [],
                            passed_courses: [],
                            firstname: firstname || "no first name",
                            lastname: lastname || "no last name",
                            studentnumber: studentNumber,
                            year: parseInt(year) || 2007,
                            term: parseInt(term) || 0,
                            curriculum: {_ref: (curriculum || curricula[0]._id)}
                        }});
                    }} />
                </div>
                    </ContainerPadding>
                </Wrapper>

                
                
                

                
            </Collapsible>
        )
    }

    // const [state, setState] = useState(STATES.INIT);
    // const [email, setEmail] = useState('');
    // const [firstname, setFirstname] = useState('');
    // const [lastname, setLastname] = useState('');
    // const [year, setYear] = useState(2007);
    // const [term, setTerm] = useState(0);
    // const [legacy, setLegacy] = useState(false);
    // const [studentNumber, setStudentNumber] = useState('')
    // const [busy, setBusy] = useState(false);
    // const client = useSelector(state=>state.data.client);
    // const width = "110px";

    // const responseGoogle = (response) => {
    //     console.log(response);
        

    //     let profile = response.profileObj;
    //     if (client.checkEmail(profile.email)){
    //         setEmail(profile.email);
    //         setFirstname(profile.givenName);
    //         setLastname(profile.familyName);
    //         setState(STATES.NEXT);
    //     } else {
    //         // do nothing?
    //     }
    // }    

    // const register = ()=>{
    //     setBusy(true);
    //     client.register(email, firstname, lastname, parseInt(year), parseInt(term), studentNumber, legacy==="true").then(()=>{
    //         setBusy(false);
    //     }).catch(()=>{setBusy(false)})
    // }

    // const getYears = () => {
    //     const year = 2007;
    //     return (
    //       Array.from( new Array(new Date().getFullYear() - year + 2), (v,i) =>
    //         <option key={i} value={year+i}>{year+i}</option>
    //       )
    //     );
    //   };

    // const getTerms = ()=>Array.from(new Array(3), (v, i) => <option key={i} value={i}>term {i+1}</option>)
    // const getLegacy = ()=>["Regular", "Legacy (old)"].map((text, i)=><option key={i} value={i==1}>{text}</option>)

    // const getInitContent = ()=>(
    //     <Section>
    //         <GoogleLogin
    //             clientId={googleAuth.clientId}
    //             onSuccess={responseGoogle}
    //             onFailure={responseGoogle}
    //             cookiePolicy={'single_host_origin'}
    //             render={renderProps => (
    //                 <Wrapper>
    //                     <ContainerPadding>
    //                         <Section style={{textAlign: "center", fontSize:"60px"}}>
    //                             <Google />
    //                         </Section>
    //                         <Button text="Connect with UPOU Google account" onClick={renderProps.onClick} />
    //                     </ContainerPadding>
    //                 </Wrapper>
                    
    //             )}
    //         />
    //     </Section>
    // )

    // const getNextContent = ()=>(<Section>
    //     <LineInput value={firstname} setValue={setFirstname} title="first name" width={width} noBottom noTop/>
    //             <LineInput value={lastname} setValue={setLastname} title="last name" width={width} noBottom/>
    //             <LineInput value={email} setValue={()=>{}} title="email" width={width} noBottom/>
    //             <LineInput value={studentNumber} setValue={setStudentNumber} title="student number" width={width} noBottom/>
    //             <Wrapper>
    //                 <ContainerPadding size={"10px"}>
    //                     <TextInline>
    //                         <b style={{width: "500px"}}>
    //                             Year first enrolled
    //                         </b>
    //                         <Select onChange={e => setYear(e.target.value)}>
    //                         {getYears()}
    //                     </Select>
    //                     </TextInline>
    //                 </ContainerPadding>
    //             </Wrapper>
    //             <Wrapper noTop noBottom>
    //                 <ContainerPadding size={"10px"}>
    //                     <TextInline>
    //                         <b  style={{width: "500px"}}>
    //                             Term first enrolled
    //                         </b>
    //                         <Select onChange={e => setTerm(e.target.value)}>
    //                         {getTerms()}
    //                     </Select>
    //                     </TextInline>
    //                 </ContainerPadding>
    //             </Wrapper>
    //             <Wrapper noBottom>
    //                 <ContainerPadding size="10px">
    //                     <TextInline>
    //                         <b  style={{width: "500px"}}>
    //                             Curriculum&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    //                         </b>
    //                         <Select onChange={e => setLegacy(e.target.value)}>
    //                         {getLegacy()}
    //                     </Select>
    //                     </TextInline>
    //                 </ContainerPadding>
    //             </Wrapper>
                
    //             {busy? <Button text="loading" onClick={()=>{}}/> : <Button text="submit" onClick={register} noBottom /> }
    // </Section>)

    // if (!client){
    //     return null;
    // } else {
    //     return (
    //         <Collapsible title="register" noPadding noWrapper>
    //             {state == STATES.INIT? getInitContent():getNextContent()}
    //         </Collapsible>
    //     )
    // }
}
