import sanityClient from '@sanity/client'
import envi from './environment'
import {settings} from './connector_settings.json'
import { ACTIONS as DATA_ACTIONS } from "../reducers/DataReducer"
import toast from '../services/toast'
import SimpleCrypto from "simple-crypto-js";
import { nanoid } from 'nanoid'


const prevFetched = {};

export const client = (dispatch)=>{
    const environment = envi();
    var crypto = new SimpleCrypto("its wednesday my dudes");
    let _ = settings;
    const sc = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        token: _.token, 
        ignoreBrowserTokenWarning: true
    })

    const scd = sanityClient({
        projectId: _.projectId,
        dataset: _.dataset,
        ignoreBrowserTokenWarning: true,
        useCdn: true
    })

    const fetch = (query, force)=>{
        if (environment.dev){
            console.log(`%c ${query}!`, 'color: darkblue');

        }
        return new Promise((resolve, reject)=>{
            if (prevFetched[query] && !force){
                if (environment.dev){
                    console.log("reused cached query");
                    console.log(query);
                }
                resolve(prevFetched[query]);
            } else {
                sc.fetch(query)
                .then((data)=>{
                    prevFetched[query] = data;
                    resolve(data);
                }).catch(reject);
            }
        })
    }

    const checkEmail = (email)=>{
        let v = (email.indexOf("upou.edu.ph")!==-1 || email.indexOf("up.edu.ph")!==-1 || email.indexOf("seads.network")!==-1); 

        if (!v){
            toast('please use a valid upou.edu.ph account');
        }

        return v;
    }

    const logout = ()=>{
        dispatch({type: DATA_ACTIONS.SET_STUDENT, student: null})
        dispatch({type: DATA_ACTIONS.SET_DATA, courseData:null});
        toast('successfully logged out')
    }

    const changePassword = (student, password, password2)=>{
        return new Promise((resolve, reject)=>{
            if (password === password2){
                sc.patch(student._id)
                .set({password: crypto.encrypt(password)})
                .commit()
                .then((newstudent)=>{
                    if (newstudent._id){
                        dispatch({type: DATA_ACTIONS.SET_STUDENT, student: newstudent});
                        toast('Password change succesful');
                        resolve();
                    } else {
                        toast('something went wrong');
                        reject();
                    }
                    
                }).catch(()=>{reject()})
            } else {
                toast('Passwords do not match');
                reject();
            }
        })
    }

    const studentQuery = (email)=>`*[email=="${email}"][0]{_id, email, notes, planned_courses, passed_courses[]->, firstname, lastname, studentnumber, year, term, curriculum}`

    const login = (email)=>{
        return new Promise((resolve, reject)=>{
            if (checkEmail(email)){
                fetch(studentQuery(email))
                .then((student)=>{
                    if (student && student._id){
                        student.trimester = ["Term 1", "Term 2", "Term 3"].indexOf(student.term);
                        dispatch({type: DATA_ACTIONS.SET_STUDENT, student});
                        toast('Log in succesful');
                        resolve();
                    } else {
                        toast('Email did not match, contact Program Chair if problem persists');
                        reject();
                    }
                }).catch((err)=>{
                    console.log(err);
                    toast('Email did not match, contact Program Chair if problem persists');
                    reject();
                })
            } else {
                reject();
            }
        })
    }

    //todo, change legacy to curriculum
    const register = (email, firstname, lastname, year, trimester, studentnumber, legacy)=>{

        return new Promise((resolve, reject)=>{
            if (true){
                if (email && firstname && lastname && studentnumber){
                    if (checkEmail(email)){
                        fetch(studentQuery(email))
                        .then((found)=>{
                            if (found && found._id){
                                toast('That email address is already taken, contact Program Chair');
                                reject();
                            } else {
                                sc.create({
                                    _type: 'student',
                                    email,
                                    lastname, firstname, studentnumber, year, 
                                    term : ["Term 1", "Term 2", "Term 3"][trimester], 
                                    legacy
                                  }).then(student => {
                                     dispatch({type: DATA_ACTIONS.SET_STUDENT, student});
                                     toast('Created new account');
                                     toast('Log in succesful');
                                     resolve(student);
                                  })
                            }
                        })
                    } else {
                        reject();
                    }
                } else {
                    const checker = (el, name)=>{
                        if (!el){
                            toast(`${name} field is missing`)
                        }
                    }

                    checker(email, "email");
                    checker(firstname, "firstname");
                    checker(lastname, "lastname");
                    checker(studentnumber, 'studentnumber');
                    
                    reject();
                }

                
            } else {
                toast('Passwords do not match');
                reject();
            }
        })
    }

    const save =(student, sections)=>{

        return new Promise((resolve, reject)=>{
            const deleteitems = (items)=>{
                return items.reduce((p, item) => {
                   return p.then(() => {
                       if (item._id){
                        sc.delete(item._id)
                       }
                    });
                }, Promise.resolve()); 
              };
    
            let counter = 0;
    
            fetch(studentQuery(student.email))
            .then(oldstudent=>{
                sc.patch(student._id)
                .set({notes: [], passed_courses: [], planned_courses: []})
                .commit()
                .then(()=>{
                    //give sanity some time to handle the removal
                    setTimeout(() => {
                        if (oldstudent.notes && oldstudent.notes.length>0){ 
                            deleteitems(oldstudent.notes || []);
                        }
                        
                        if (oldstudent.planned_courses && oldstudent.planned_courses.length > 0){
                            deleteitems(oldstudent.planned_courses || []);
                        }
                    }, 200);
    
                    sections.forEach((section, sectionidx)=>{
                        section.courses.forEach((course, courseidx, courses) =>{
                            setTimeout(() => {
                                //save the course notes
                                if (course.note){
                                    sc.patch(student._id)
                                    .setIfMissing({notes: []})
                                    .insert('after', 'notes[-1]', [
                                        {  _key: nanoid(),
                                            _type: "note",
                                            course: {_ref: course._id},
                                            content: course.note.content 
                                        }
                                    ])
                                    .commit()
                                }
                                
                                //save the passed course
                                if (course.passed){
                                    sc.patch(student._id)
                                    .setIfMissing({passed_courses: []})
                                    .insert('after', 'passed_courses[-1]', [
                                        {_key: nanoid(), _ref: course._id}
                                    ])
                                    .commit()
                                }

                                // save the planned course
                                if (course.planned){
                                    sc.patch(student._id)
                                    .setIfMissing({planned_courses: []})
                                    .insert('after', 'planned_courses[-1]', [
                                        {   _key: nanoid(), 
                                            _type: "planned_course",
                                            course: {_ref: course._id},
                                            year: course.planned.year,
                                            trimester: course.planned.trimester
                                        }
                                    ])
                                    .commit()
                                
                                //deprecated
                                //     sc.create({
                                //         _type: "planned_course",
                                //         course: {_ref: course._id},
                                //         year: course.planned.year,
                                //         trimester: course.planned.trimester
                                //     }).then(planned=>{
                                //         sc.patch(student._id)
                                //             .setIfMissing({planned_courses: []})
                                //             .insert('after', 'planned_courses[-1]', [
                                //                 {_key: nanoid(), _ref: planned._id}
                                //             ])
                                //             .commit()
                                //     }).catch(error=>console.log(error));
                                }
    
                                if (sectionidx === Array.from(sections)[sections.size-1][0] && courseidx === courses.length-1){
                                    setTimeout(() => {
                                        resolve();
                                    }, 1000);
                                }
    
                            }, counter+=100);
                        })
                    })
                })
            })
        })
    }

    const loadUserData = (courseData, student)=>{

        console.log("hoi")

        //load curriculum
        fetch(`*[_id == "${student.curriculum._ref}"][0]{name, _id, credits_required, section[]{name, amount_required, courses[]->{name, credits, trimester, requirements[]->{name, _id}, credit_requirements, _id, course_page}}}`)
        .then(curriculum=>{
            //set settings 

            console.log(curriculum)
            
            dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings: {creditsRequired: curriculum.credits_required || 0}})

            let courses = [];
            (curriculum.section || []).forEach(section=>{
                (section.courses || []).forEach(course=>{
                    course.section = section;
                    courses.push(course);
                })
                // delete section.courses;
            })
            
            //actually load the data
            courseData.init(student.notes || [], courses, student.passed_courses || [], student.planned_courses || []);

            //safe alternative, without data
            // courseData.init([], courses,[], []);
        }).catch((err)=>{
            console.log(err);
            toast('Userdata could not be loaded');
        })

        //deprecated
        // fetch(`*[_type=='${student.legacy?"legacy_course":"course"}']{name, credits, section->{name, amount_required}, trimester[]->{name}, requirements[]->{name, _id}, credit_requirements, _id, course_page}`)
        // .then((courses)=>{
        //     loadSettings(student);
        //     courseData.init(student.notes || [], courses, student.passed_courses || [], student.planned_courses || []);
        // })
    }

    const loadCurricula = ()=>{
        fetch(`*[_type == "curriculum"]`)
        .then(curricula=>{
            dispatch({type: DATA_ACTIONS.SET_CURRICULA, curricula})
        })

        
    }

    //deprecated
    // const loadSettings = (student)=>{
    //     return new Promise((resolve, reject)=>{
    //         fetch(`*[_type == "settings" && title == "default"][0]`)
    //         .then(settings=>{
    //             resolve()
    //             dispatch({type: DATA_ACTIONS.SET_SETTINGS, settings: {creditsRequired: student.legacy?settings.legacy_credits_required:settings.regular_credits_required}})
    //         })
    //     })
    // }

    if (environment.dev){
        environment.printstatus()
    } 

    return {
        fetch,
        environment,
        register, login, logout, changePassword, loadUserData, save, checkEmail, loadCurricula
    }
}

export const FETCH_STATES = Object.freeze({
    IDLE: Symbol("fetch/idle"),
    BUSY: Symbol("fetch/busy"),
    ERROR: Symbol("fetch/error"),
});

export default client;

